import {memo, ReactNode} from 'react';
import {Card, CardHeader, Col, Row} from 'reactstrap';

type Props = {
  countyName: string
  countyLevel: string
  children: ReactNode
}

const CountyBookCard = ({
                          countyName,
                          countyLevel,
                          children
                        }: Props) => {
  return (
    <Card className="mb-3">
      <CardHeader className="bg-secondary text-white text-uppercase">
        <Row noGutters>
          <Col>
            {countyName}
          </Col>
          <Col className="d-flex justify-content-end">
            {countyLevel}
          </Col>
        </Row>
      </CardHeader>
      {children}
    </Card>
  );
};

export default memo(CountyBookCard);