import * as Yup from 'yup';

import {YupPhone} from '@reasoncorp/kyber-js';

import * as messages from '../messages';
import {addressCompleteTest} from './validationUtils';

export default Yup.object().shape({
  unitId: Yup.number().required(messages.REQUIRED),
  role: Yup.string().required(messages.REQUIRED),
  firstName: Yup.string(),
  lastName: Yup.string(),
  phoneNumber: new YupPhone(messages.BAD_PHONE_NUMBER_FORMAT).schema(),
  email: Yup.string().email(messages.EMAIL_INVALID),
  street: Yup.string().test(addressCompleteTest),
  city: Yup.string().test(addressCompleteTest),
  state: Yup.string().test(addressCompleteTest),
  zip: Yup.string().test(addressCompleteTest)
});