import {memo} from 'react';

import {ButtonIcon, CustomTable} from '@reasoncorp/kyber-js';
import {Municipal} from '../types';

type Props = {
  municipals: Municipal[]
  onEdit: (municipal: Municipal) => void
}

const MunicipalTable = ({
                          municipals,
                          onEdit
                        }: Props) => {
  const tableProps = {
    headers: [
      {title: 'Unit', className: 'text-nowrap'},
      {title: 'Municipal Address', className: 'text-nowrap'},
      {title: 'Phone', className: 'text-nowrap'},
      {title: 'Edit', className: 'text-nowrap text-center'}
    ],
    items: municipals,
    renderRow: (municipal: Municipal) => {
      return (
        <tr key={`${municipal.id}`}>
          <td className="align-middle w-30">{municipal.name} {municipal.type}</td>
          <td className="align-middle w-50">{municipal.addressDisplay}</td>
          <td className="align-middle w-20">{municipal.phoneNumber}</td>
          <td className="align-middle text-center edit-cell">
            <ButtonIcon icon="cog"
                        title={`Edit ${municipal} ${municipal.type} Address`}
                        ariaLabel={`Edit ${municipal} ${municipal.type} Address`}
                        className="text-primary"
                        onClick={() => onEdit(municipal)}/>
          </td>
        </tr>
      );
    }
  };

  return (
    <CustomTable {...tableProps}/>
  );
};


export default memo(MunicipalTable);