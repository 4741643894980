import {memo} from 'react';

import {AppBar, SOMLogo, SsoNotificationButton, User, UserProfile} from '@reasoncorp/kyber-js';

import config from '../config';
import {ssoApi} from '../api';

type Props = {
  currentUser?: User
}

const CountyBookAppBar = ({
                            currentUser
                          }: Props) => {

  const handleProfileClick = () => {
    window.location.href = `${config.sso.webUrl}/user-profile`;
  };

  const handleNotificationsClick = () => {
    window.location.href = `${config.sso.webUrl}/notifications`;
  };

  const handleSignOut = async () => {
    await ssoApi.signOut();
  };

  const routes = [
    {name: 'County Book', to: '/county-book'},
    {name: 'Municipals', to: '/municipals'}
  ];

  const renderNotificationButton = currentUser ? () =>
    <SsoNotificationButton onNotificationsClick={handleNotificationsClick}
                           ssoApi={ssoApi}/> : undefined;

  const renderUserProfile = currentUser ? () =>
    <UserProfile handleProfileClick={handleProfileClick} handleSignOut={handleSignOut}/> : undefined;

  return (
    <AppBar brandLink={`${config.sso.webUrl}/dashboard`}
            brandImage={SOMLogo}
            brandImageAlt="MiSuite"
            appName="Michigan County Book"
            organizationName="The Department of Treasury"
            environmentName={config.envName !== 'prod' ? config.envName : undefined}
            routes={routes}
            renderNotificationButton={renderNotificationButton}
            renderUserProfile={renderUserProfile}/>
  );
};

export default memo(CountyBookAppBar);
