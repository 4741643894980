import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {AlertsProvider, MiSuiteJurisdiction, MiSuiteRole, ssoUtils, User, UserProvider} from '@reasoncorp/kyber-js';
import './index.scss';
import App from './App';
import config from './config';
import {ssoApi} from './api';

if (config.envName !== 'prod') {
  document.title = `${config.envName.toUpperCase()} - ${document.title}`;
}

const setPermissions = (currentUser?: User) => ({
  isSsoAdmin: ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.SSO_APP, MiSuiteRole.ADMIN)
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <UserProvider ssoApi={ssoApi} setPermissions={setPermissions}>
      <AlertsProvider>
        <App/>
      </AlertsProvider>
    </UserProvider>
  </BrowserRouter>
);