import {Navigate, Route, Routes} from 'react-router-dom';

import {Alerts, MiFooter, NotFoundV2, SkipNav, useUserContext} from '@reasoncorp/kyber-js';

import './font-awesome-icons';
import {CountyBookAppBar} from './components';
import {CountyBook, Municipals} from './containers';

const App = () => {
  const {currentUser, loadingUser} = useUserContext();
  return (
    <>
      <SkipNav/>
      <CountyBookAppBar currentUser={currentUser}/>
      {!loadingUser &&
        <main role="main" id="content">
          <Routes>
            <Route path="/" element={<Navigate replace to="/county-book"/>}/>
            <Route path="/county-book" element={<CountyBook/>}/>
            <Route path="/municipals" element={<Municipals/>}/>
            <Route path="*" element={<NotFoundV2/>}/>
          </Routes>
        </main>
      }
      <MiFooter/>
      <Alerts/>
    </>
  );
};

export default App;