import {cbApi} from './apiUtils';

import {Municipal, MunicipalEntry} from '../types';
import {MunicipalFormFields} from '../types/forms';

export const findAll = (): Promise<MunicipalEntry[]> => cbApi.getWithJsonResponse('municipals');

export const update = (id: number, municipalRequest: MunicipalFormFields): Promise<Municipal> => cbApi.putWithJsonResponse(
  `municipals/${id}`,
  {
    body: JSON.stringify(municipalRequest)
  }
);