import {memo} from 'react';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';

import {FormikInput, FormikPhoneInput, FormikRadioGroup, FormikSelect, MiSuiteRole, UsState} from '@reasoncorp/kyber-js';
import {contactFormSchema} from '../schema';
import {CountyBookRowEntry} from '../types';
import {ContactFormFields} from '../types/forms';

type Props = {
  isOpen: boolean
  entry?: CountyBookRowEntry
  onToggle: (countyBookRowEntry?: CountyBookRowEntry) => void
  onSave: (formikValues: ContactFormFields, formikHelpers: FormikHelpers<ContactFormFields>) => void
}

const ContactModal = ({
                        isOpen,
                        entry,
                        onToggle,
                        onSave
                      }: Props) => {
  const modalHeader = entry ? entry.unit.type === 'City' ?
      `Edit City Manager/Mayor for ${entry.unit.name} ${entry.unit.type}` :
      `Edit Township Supervisor for ${entry.unit.name} ${entry.unit.type}`
    : '';
  const initialValues: ContactFormFields = {
    unitId: entry?.unit.id ?? -1,
    role: entry?.contact.role ?? '',
    cbContactId: entry?.contact.cbContactId ?? -1,
    firstName: entry?.contact.firstName ?? '',
    lastName: entry?.contact.lastName ?? '',
    phoneNumber: entry?.contact.phoneNumber ?? '',
    email: entry?.contact.email ?? '',
    street: entry?.contact.address?.street ?? '',
    city: entry?.contact.address?.city ?? '',
    // Pre-populate to MI if contact has not been saved yet
    state: !entry?.contact.cbContactId ? 'MI' : entry?.contact.address?.state ?? '',
    zip: entry?.contact.address?.zip ?? ''
  };

  const handleClose = (formikProps: FormikProps<ContactFormFields>) => {
    formikProps.resetForm();
    onToggle();
  };

  if (!entry) {
    return null;
  } else {
    return (
      <Formik initialValues={initialValues}
              validationSchema={contactFormSchema}
              enableReinitialize={true}
              onSubmit={onSave}>
        {(formikProps) => {
          return (
            <Modal className="ContactModal"
                   autoFocus={false}
                   backdrop="static"
                   size="lg"
                   isOpen={isOpen}
                   toggle={() => handleClose(formikProps)}>
              <ModalHeader toggle={() => handleClose(formikProps)}>{modalHeader}</ModalHeader>
              <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
                <ModalBody>
                  {entry?.unit.type === 'City' &&
                  <Row>
                    <Col>
                      <FormikRadioGroup name="role"
                                        inline
                                        radioButtons={[
                                          {labelText: 'City Manager', value: MiSuiteRole.CITY_MANAGER},
                                          {labelText: 'Mayor', value: MiSuiteRole.MAYOR}
                                        ]}
                                        formGroupClass="role-radio-buttons"/>
                    </Col>
                  </Row>
                  }
                  <Row>
                    <Col md="6">
                      <FormikInput name="firstName"
                                   labelText="First Name"
                                   maxLength={50}/>
                    </Col>
                    <Col md="6">
                      <FormikInput name="lastName"
                                   labelText="Last Name"
                                   maxLength={50}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormikPhoneInput name="phoneNumber"
                                        labelText="Phone Number"
                                        extensionLength={5}
                                        maxLength={50}/>
                    </Col>
                    <Col md="6">
                      <FormikInput name="email"
                                   labelText="Email"
                                   maxLength={50}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormikInput name="street"
                                   labelText="Street Address"
                                   maxLength={100}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormikInput name="city"
                                   labelText="City"
                                   maxLength={50}/>
                    </Col>
                    <Col md="4">
                      <FormikSelect name="state"
                                    labelText="State">
                        <option value="">Select</option>
                        {Object.keys(UsState).map(state => {
                          return (
                            <option key={state} value={state}>{state}</option>
                          );
                        })}
                      </FormikSelect>
                    </Col>
                    <Col md="4">
                      <FormikInput name="zip"
                                   labelText="Zip Code"
                                   maxLength={20}/>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color="success"
                          onClick={formikProps.submitForm}
                          disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                    Save
                  </Button>
                  <Button color="secondary"
                          onClick={() => handleClose(formikProps)}
                          disabled={formikProps.isSubmitting}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Form>
            </Modal>
          );
        }}
      </Formik>
    );
  }
};

export default memo(ContactModal);