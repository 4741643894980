import {cbApi} from './apiUtils';
import {ContactFormFields} from '../types/forms';
import {Contact} from '../types';

export const create = (contactRequest: ContactFormFields): Promise<Contact> => cbApi.postWithJsonResponse(
  'contacts',
  {
    body: JSON.stringify(contactRequest)
  }
);

export const update = (id: number, contactRequest: ContactFormFields): Promise<Contact> => cbApi.putWithJsonResponse(
  `contacts/${id}`,
  {
    body: JSON.stringify(contactRequest)
  }
);