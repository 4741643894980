// Growl Messages
export const UNABLE_TO_LOAD_DATA = 'Unable to load data.';
export const EXPORT_SUCCESSFUL = 'Export successful.';
export const EXPORT_FAILED = 'Export failed.';
export const USER_UPDATE_SUCCESSFUL = 'User Updated.';
export const USER_UPDATE_FAILED = 'Unable to Update User.';
export const MUNICIPAL_UPDATE_SUCCESSFUL = 'Municipal Updated.';
export const MUNICIPAL_UPDATE_FAILED = 'Unable to Update Municipal.';

// Validation messages
export const REQUIRED = 'Required';
export const INCOMPLETE_ADDRESS_NOT_ALLOWED = 'Address fields must be complete or all empty.';
export const BAD_PHONE_NUMBER_FORMAT = 'Format must be (234) 244-1244 or (234) 244-1244 x123.';
export const EMAIL_INVALID = 'Invalid Email Address.';