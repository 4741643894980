import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';

import {FormikInput, FormikPhoneInput, FormikSelect} from '@reasoncorp/kyber-js';
import {Municipal} from '../types';
import {MunicipalFormFields} from '../types/forms';
import {municipalFormSchema} from '../schema';

type Props = {
  isOpen: boolean
  municipal?: Municipal
  onToggle: (municipal?: Municipal) => void
  onSave: (formikValues: MunicipalFormFields, formikHelpers: FormikHelpers<MunicipalFormFields>) => void
}

const MunicipalModal = ({
                          isOpen,
                          municipal,
                          onToggle,
                          onSave
                        }: Props) => {
  const modalHeader = `Edit Address for ${municipal?.name} ${municipal?.type}`;
  const initialValues: MunicipalFormFields = {
    street: municipal?.address?.street ?? '',
    city: municipal?.address?.city ?? '',
    state: municipal?.address?.state ?? 'MI',
    zip: municipal?.address?.zip ?? '',
    phoneNumber: municipal?.phoneNumber ?? ''
  };

  const handleClose = (formikProps: FormikProps<MunicipalFormFields>) => {
    onToggle();
    formikProps.resetForm();
  };

  return (
    <Formik initialValues={initialValues}
            validationSchema={municipalFormSchema}
            enableReinitialize={true}
            onSubmit={onSave}>
      {(formikProps) => {
        return (
          <Modal className="ContactModal"
                 autoFocus={false}
                 backdrop="static"
                 size="lg"
                 isOpen={isOpen}
                 toggle={() => handleClose(formikProps)}>
            <ModalHeader toggle={() => handleClose(formikProps)}>{modalHeader}</ModalHeader>
            <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
              <ModalBody>
                <Row>
                  <Col>
                    <FormikInput name="street"
                                 labelText="Street Address"
                                 maxLength={100}/>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormikInput name="city"
                                 labelText="City"
                                 maxLength={50}/>
                  </Col>
                  <Col md="4">
                    <FormikSelect name="state"
                                  labelText="State">
                      <option value="">Select</option>
                      <option value="MI">MI</option>
                    </FormikSelect>
                  </Col>
                  <Col md="4">
                    <FormikInput name="zip"
                                 labelText="Zip Code"
                                 maxLength={20}/>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormikPhoneInput name="phoneNumber"
                                      labelText="Phone Number"
                                      extensionLength={5}
                                      maxLength={50}/>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="success"
                        onClick={formikProps.submitForm}
                        disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                  Save
                </Button>
                <Button color="secondary"
                        onClick={() => handleClose(formikProps)}
                        disabled={formikProps.isSubmitting}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default MunicipalModal;